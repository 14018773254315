
import { Component, Mixins, Prop } from "vue-property-decorator";
import {
  NavBar,
  Form,
  Field,
  Popup,
  Picker,
  Cell,
  Switch,
  Toast,
  Image,
} from "vant";
import { Mixin } from "@/core/mixins/mixin";
import { monitorEvent } from "@/utils/youMengMonitor";

@Component({
  components: {
    Mixin,
    [NavBar.name]: NavBar,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Cell.name]: Cell,
    [Switch.name]: Switch,
    [Image.name]: Image,
    [Form.name]: Form,
  },
})
export default class AddressDetail extends Mixins(Mixin) {
  @Prop({ default: false }) public component?: boolean;
  @Prop({ default: false }) public edit?: boolean; // 是否从编辑页面过来
  @Prop({ default: "" }) public addrDispProvId!: string; // 地址id
  @Prop({ default: "" }) public addrDispCityId!: string; // 地址id
  @Prop({ default: "" }) public addrDispCounId!: string; // 地址id
  @Prop({ default: "" }) public addrId!: string; // 地址id
  @Prop({ default: "" }) public addrTel!: string; // 电话
  @Prop({ default: "" }) public addrName!: string; // 姓名
  @Prop({ default: "" }) public addrAddress!: string; // 详细地址
  @Prop({ default: "" }) public addrarea?: string; // 所在地区
  @Prop({ default: "" }) public addrIsDefault?: string; // 是否是默认地址

  @Prop({
    default: () => {
      return [];
    },
  })
  newAddrDistributionScope?: Array<string>;

  //表情校验正则
  public regStr =
    /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030/gi;

  public form: member.AddrReq = {
    id: undefined,
    addrStatus: "0",
    addrName: "",
    addrMobile: "",
    addrDitailAddr: "",
    addrDispProvId: "",
    addrDispCityId: "",
    addrDispCounId: "",
  };

  public region = {
    show: false,
    form: "",
    select: [],
    init: [],
  };

  addNameReg(): void {
    if (this.form.addrName.length >= 25) {
      this.form.addrName = this.form.addrName.slice(0, 25);
      this.$toast("请输入正确的收货人");
      return;
    }
    if (this.regStr.test(this.form.addrName)) {
      this.form.addrName = this.form.addrName.replace(this.regStr, "");
      this.$toast("请输入正确的收货人");
    }
  }
  addMobileReg(): void {
    if (this.form.addrMobile.length > 11) {
      this.form.addrMobile = this.form.addrMobile.slice(0, 11);
      this.$toast("请输入正确的手机号");
      return;
    }
  }
  telReg(): void {
    let regTel =
      /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
    if (!regTel.test(this.form.addrMobile)) {
      this.$toast("请输入正确的手机号");
    }
  }

  /**
   * 刷新数据
   * 先清空，然后判断如果是编辑则设置父组件传入的值
   */
  refreshData(): void {
    window.document.title = "添加收货地址";
    this.clearForm();
    if (this.edit) {
      this.form.id = this.addrId;
      this.form.addrStatus = this.addrIsDefault || "0";
      this.form.addrName = this.addrName;
      this.form.addrMobile = this.addrTel;
      this.form.addrDitailAddr = this.addrAddress;
      this.form.addrDispProvId = this.addrDispProvId;
      this.form.addrDispCityId = this.addrDispCityId;
      this.form.addrDispCounId = this.addrDispCounId;
      this.region.form = this.getAddress();
    }
  }

  getAddress(): string {
    let addressIdNameMap = this.DictionaryModule.addressIdNameMap;
    // this.$toast(addressIdNameMap);
    let addrDispProvId = this.addrDispProvId;
    if (!addrDispProvId) {
      return "";
    }
    let provinceName = addressIdNameMap[addrDispProvId];
    if (!provinceName) {
      return "";
    }
    return (
      provinceName +
      "," +
      (addressIdNameMap[this.addrDispCityId] || "") +
      "," +
      (addressIdNameMap[this.addrDispCounId] || "")
    );
  }

  clearForm(): void {
    this.form.id = undefined;
    this.form.addrStatus = "0";
    this.form.addrName = "";
    this.form.addrMobile = "";
    this.form.addrDitailAddr = "";
    this.form.addrDispProvId = "";
    this.form.addrDispCityId = "";
    this.form.addrDispCounId = "";
    this.region.form = "";
  }

  // 保存收货地址
  saveAddress(): void {
    // 打开加载动画
    Toast.loading({
      duration: 0, // 持续展示 toast
      forbidClick: true,
    });
    if (this.form.addrName === "") {
      this.$toast("请输入收货人名称");
      return;
    }
    if (this.form.addrMobile === "") {
      this.$toast("请输入手机号");
      return;
    }
    if (this.region.form === "") {
      this.$toast("请选择所在地区");
      return;
    }
    if (this.form.addrDitailAddr === "") {
      this.$toast("请输入详细地址");
      return;
    }
    let regName = new RegExp(
      "[~!@#$%^&*()=+[\\]{}''\";:/?.,><`|！·￥…—（）\\-、；：。，'‘》《]"
    );
    if (regName.test(this.form.addrName)) {
      this.$toast("请输入正确的收货人");
      return;
    }
    let regTel = /^[0-9]+(\.[0-9]{8,11})?$/g;
    if (!regTel.test(this.form.addrMobile)) {
      this.$toast("请输入正确的手机号");
      return;
    }

    if (!this.form.id && this.$route.name == "address") {
      monitorEvent("MyAddress_Add", "新增收货地址"); // 埋点：收货地址列表，新增收货地址
    }

    this.$api.memberApi.address.saveOrUpdate(this.form, ({ data }) => {
      Toast.clear(); // 关闭加载动画
      this.clearForm();
      this.$emit("save", data);
    });
  }

  // 删除收货地址
  deleteAddr(): void {
    if (this.form.id) {
      this.$api.memberApi.address.remove(this.form.id, (data) => {
        if (data.data) {
          this.$toast("删除成功");
          this.$emit("save");
        }
      });
    } else {
      this.$toast("操作失败");
    }
  }

  selectRegion(picker: Array<string>, arr: Array<number>): void {
    this.region.show = false;
    this.region.form = picker.join();
    let index1 = arr[0]; // 第一个数组索引
    let index2 = arr[1]; // 第二个数组索引
    let index3 = arr[2]; // 第三个数组索引
    let province = this.DictionaryModule.addresses[index1]; // 省份
    this.form.addrDispProvId = province.id;
    let cities = province.children; // 该省份下的所有地级市
    // 比较一下 index2 防止数组越界
    if (cities && cities.length > index2) {
      let city = cities[index2]; // 选中的地级市
      this.form.addrDispCityId = city.id;
      let countries = city.children; // 该地级市下边的所有乡镇
      // 比较一下 index3 防止数组越界
      if (countries && countries.length > index3) {
        this.form.addrDispCounId = countries[index3].id;
      }
    }
  }

  onClickLeft(): void {
    this.$emit("close");
  }

  changeSwitch(): void {
    // console.log("changeSwitch");
  }
}
