
import { Component, Mixins, Watch, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import {
  Col,
  Row,
  NavBar,
  Icon,
  Button,
  Image,
  Swipe,
  SwipeItem,
  Tabs,
  Tab,
  Popup,
  Stepper,
  Dialog,
  Checkbox,
} from "vant";
// import { ProjectDetailsCouponDetailes } from "@/shims-video";
@Component({
  components: {
    Mixin,
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
    [Col.name]: Col,
    [Row.name]: Row,
    [Button.name]: Button,
    [Image.name]: Image,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Popup.name]: Popup,
    [Checkbox.name]: Checkbox,
    [Stepper.name]: Stepper,
    [Dialog.Component.name]: Dialog.Component,
  },
})
export default class whCoupon extends Mixins(Mixin) {
  @Prop()
  public cultureCouponIds!: Array<string>;
  public checked: Array<boolean> = [];
  public cultureCouponshow = false;
  public checkItem = {};
  @Watch("cultureCouponIds")
  changeCultureCoupon(val: []): void {
    this.cultureCouponIds = val;
  }

  public cultureCoupon: Array<ObjectMap> = [];
  public couponCode = "";

  mounted(): void {
    this.getCouponLit();
  }

  //文惠券列表
  getCouponLit(): void {
    let pro_arr: any = [];
    this.cultureCouponIds.forEach((id, index) => {
      pro_arr.push(this.couponLit(id));
    });
    Promise.all(pro_arr).then((val) => {
      console.log("hh all", val);
    });
  }
  couponLit(id: string): Promise<unknown> {
    //this.cultureCouponIds

    return new Promise(() => {
      let i = 0;
      this.$api.goodApi.coupon.cultureCoupons(id, ({ data }) => {
        console.log("文惠券", data);
        this.cultureCoupon = data;
        // this.cultureCoupon.push({
        //   couponAmt: 100,
        //   couponDesc: "123",
        //   couponName: "1",
        //   couponNum: "2",
        //   couponPlatUserId: "string",
        //   createName: "string",
        //   createTime: "2021-12-02T06:06:11.624Z",
        //   createUserId: "string",
        //   cultureCouponId: "string",
        //   del: 0,
        //   endTime: "2021-12-02T06:06:11.624Z",
        //   id: i++,
        //   minConsume: 0,
        //   mobile: "string",
        //   orderId: "string",
        //   relname: "string",
        //   shopQuota: 0,
        //   startTime: "2021-12-02T06:06:11.624Z",
        //   status: "string",
        //   theaterId: "string",
        //   trades: "string",
        //   updateName: "string",
        //   updateTime: "2021-12-02T06:06:11.624Z",
        //   updateUserId: "string",
        //   userId: "string",
        //   versionNum: 0,
        // });
        // console.log("文惠券", data);
      });
    });
  }

  // 领取优惠券
  receiveCoupon(couponId: number): void {
    this.$api.goodApi.coupon.receiveCoupon(
      couponId,
      { activityId: "" },
      (data) => {
        console.log(data);
      }
    );
  }
  onClickLeft(): void {
    // this.$router.go(-1);
    if (JSON.stringify(this.checkItem) != "{}") {
      this.$emit("checkwhitem", this.checkItem);
    }

    this.$emit("close");
  }
  onClickRight(): void {
    console.log("onClickRight");
    Dialog.confirm({
      title: "是否立即解锁？",
      message: "解锁后将退还至文惠账户，可进入文惠卡公共号查看。",
      confirmButtonColor: "#000",
    })
      .then(() => {
        // on confirm
        let couponId = "";
        this.$api.goodApi.coupon.unLockCultureCoupons(couponId, (data) => {
          console.log(data.data, "解锁文惠券");
        });
      })
      .catch(() => {
        // on cancel
      });
  }
  // 兑换文惠券
  exChanegeCoupon(): void {
    console.log("兑换文惠券");

    let cultureCouponId = "542760940899442688";
    this.$api.goodApi.coupon.exchangeCultureCoupons(
      this.couponCode,
      cultureCouponId,
      (data) => {
        console.log("兑换文辉券", data);
      },
      (error) => {
        error && this.$toast(error);
      }
    );
  }
  //可用优惠券  用户 选中
  choose(ind: number): void {
    for (let i = 0; i < this.cultureCoupon.length; i++) {
      if (i == ind) {
        this.checked[i] = true;
        this.checkItem = this.cultureCoupon[i];
        if (JSON.stringify(this.checkItem) != "{}") {
          this.$emit("checkitem", this.checkItem);
          this.$emit("close");
        }
      } else {
        this.checked[i] = false;
      }
    }
    this.cultureCouponshow = false;
    this.$nextTick(() => {
      this.cultureCouponshow = true;
    });
  }
  // choose(): void {
  //   Dialog.confirm({
  //     title: "北京文惠券提示",
  //     message:
  //       "订单金额小于100元，不满足使用条件。是否立即解锁文惠券？解锁后将退还至文惠账户，可进入文惠卡公众号查看。",
  //     confirmButtonColor: "#ffcb00",
  //   }).then(() => {
  //     console.log("选择");
  //   });
  // }
}
