
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import {
  AddressList,
  NavBar,
  Popup,
  Row,
  Col,
  Image,
  Button,
  SwipeCell,
  Dialog,
} from "vant";
import { Mixin } from "@/core/mixins/mixin";
import AddressDetail from "@/views/Mine/MoreServices/Address/AddressDetail.vue";

@Component({
  components: {
    AddressDetail,
    [AddressList.name]: AddressList,
    [NavBar.name]: NavBar,
    [Popup.name]: Popup,
    [Row.name]: Row,
    [Col.name]: Col,
    [Image.name]: Image,
    [Button.name]: Button,
    [SwipeCell.name]: SwipeCell,
    [Dialog.Component.name]: Dialog.Component,
  },
})
export default class MyAddressList extends Mixins(Mixin) {
  @Prop({ default: "" }) public showId!: number;
  @Prop({
    default: () => {
      return [];
    },
  })
  public newAddrDistributionScope?: Array<string>;
  @Prop({ default: "" }) public commodityInfoId!: number;
  @Prop({ default: "" }) public type!: string; // 类型,1代表票务快递信息，2代表商品快递信息
  @Prop({ default: "" }) public uuid!: string; //订单 uuid
  public chosenAddressId = "1"; // 选择的地址
  public newAddrList: Array<member.NewAddrRes> = [];
  public editAddresses = false;
  public edit = false;
  public editName = "";
  public addrDitailAddr = "";
  public editTel = "";
  public isDefault = "";
  public addrid = "";
  public addrarea = "";
  addrDispProvId = "";
  addrDispCityId = "";
  addrDispCounId = "";

  // @Watch("showId")
  // watchShowId(value: number): void {
  //   this.showId = value;
  //   this.getOrderAddrList();
  // }

  // @Watch("commodityInfoId")
  // watchCommodityInfoId(value: number): void {
  //   this.commodityInfoId = value;
  //   this.getOrderAddrList();
  // }

  // @Watch("uuid")
  // watchUuId(value: string): void {
  //   this.uuid = value;
  //   this.getOrderAddrList();
  // }

  mounted(): void {
    this.InterfaceCacheModule.getData({
      cacheKey: "region.getRegionList",
      apiMethod: this.$api.dictionaryApi.region.getRegionList,
      callback: ({ data }) => {
        this.DictionaryModule.SET_addresses_PERSIST(data);
      },
    });
    this.$nextTick(() => {
      if (!this.type) {
        this.refreshData();
      }
    });
  }

  /**
   * 刷新页面的数据
   * 在 created() 时执行，或 popup 打开组件时由父组件调用
   */
  refreshData(): void {
    // 收货地址入口分为个人中心和订单(商品和演出)：
    // 1.订单入口需要判断选中地址是否支持包邮
    // 2.个人中心入口没有选中事件不需要判断是否包邮
    // 因为有以上两种情况，因此查询列表接口不同 这里可以通过type是否存在去判断
    // 如果type不为空 说明是从商品/演出订单入口进入 若为空即为个人中心进入
    if (this.type) {
      console.log(111);
      // 订单页--进入
      this.getOrderAddrList();
    } else {
      // 个人中心--查询当前用户的收货地址
      this.getAddressList();
    }
  }

  // 个人中心--查询当前用户的收货地址
  getAddressList(): void {
    this.$api.memberApi.address.list((data) => {
      this.newAddrList = data.data!;
    });
  }

  // 订单页--进入
  getOrderAddrList(): void {
    let params = {
      commodityInfoId: this.commodityInfoId,
      type: this.type,
      showId: this.showId ? this.showId : NaN,
      uuid: this.uuid,
    };
    this.$api.memberApi.address.getAddressWithPrice(params, ({ data }) => {
      this.newAddrList = data.newAddrList!;
    });
  }

  // 添加收货地址detail
  onAdd(): void {
    this.edit = false;
    this.editAddresses = true;
  }

  // 编辑收货地址  把现有的地址信息传过去
  onEdit(item: member.NewAddrRes): void {
    this.edit = true;
    this.editName = item.addrName || "";
    this.addrarea = item.addrarea || "";
    this.addrDitailAddr = item.addrDitailAddr || "";
    this.editTel = item.addrMobile || "";
    this.isDefault = item.addrStatus || "";
    this.addrid = item.id || "";
    this.editAddresses = true;
    this.addrDispProvId = item.addrDispProvId || "";
    this.addrDispCityId = item.addrDispCityId || "";
    this.addrDispCounId = item.addrDispCounId || "";
  }

  /*
   * 打开地址详情的弹框时执行
   */
  openAddressDetail(): void {
    this.$nextTick(() => {
      (this.$refs["address-detail"] as AddressDetail).refreshData();
    });
  }

  onClickLeft(): void {
    if (this.type) {
      //当前列表地址传给父组件，用于判断newAddrList为空时，父组件展示的默认地址也为空
      this.$emit("close", this.newAddrList);
    } else {
      if (this.AppSource == "harmonyOS") {
        if (this.newAddrList && this.newAddrList.length) {
          this.jsBridge("back", "", () => {
            this.$router.go(-1);
          });
        } else {
          this.jsBridge("onSelectAddress", undefined);
        }
      } else {
        this.$router.go(-1);
      }
    }
  }

  // 刷新收货地址列表
  refreshAddresslist(): void {
    this.editAddresses = false;
    if (this.type) {
      this.getOrderAddrList();
    } else {
      this.getAddressList();
    }
  }

  chooseAddr(item: member.NewAddrRes): void {
    if (this.type) {
      this.$emit("set", item);
    }
  }
  // 删除收货地址
  deleteAddr(id: string): void {
    Dialog.alert({
      title: "确定要删除该收货地址吗？",
      showCancelButton: true,
    }).then(() => {
      this.$api.memberApi.address.remove(id, (data) => {
        if (data.data) {
          this.$toast("删除成功");
          this.refreshAddresslist();
        }
      });
    });
  }
}
