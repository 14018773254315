
import { Component, Mixins, Watch, Vue } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import navbar from "@/components/NavBar.vue";
import AddAudience from "@/views/Mine/MoreServices/Audience/AddAudience.vue";
import { PricesInformations } from "@/shims-axios";
import MyAddressList from "@/views/Mine/MoreServices/Address/MyAddressList.vue";
import AddressDetail from "@/views/Mine/MoreServices/Address/AddressDetail.vue";
import CouponList from "@/views/Mine/AssetCenter/Coupon/OrderCouponList.vue";
import whCoupon from "@/views/Mine/AssetCenter/Coupon/whCoupon.vue";
import DistributionArea from "@/views/Payment/DistributionArea.vue";
import {
  Col,
  Row,
  NavBar,
  Icon,
  Button,
  Image,
  Swipe,
  SwipeItem,
  Tabs,
  Tab,
  Popup,
  Field,
  SubmitBar,
  CellGroup,
  Dialog,
} from "vant";
import AvailableGiftCard from "@/views/Mine/AssetCenter/GiftCard/AvailableGiftCard.vue";
import { GiftsCardRes } from "@/shims.decorator";
import { monitorEvent } from "@/utils/youMengMonitor";

@Component({
  components: {
    Mixin,
    navbar,
    AddAudience,
    MyAddressList,
    whCoupon,
    AddressDetail,
    CouponList,
    DistributionArea,
    AvailableGiftCard,
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
    [Col.name]: Col,
    [Row.name]: Row,
    [Button.name]: Button,
    [Image.name]: Image,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Popup.name]: Popup,
    [SubmitBar.name]: SubmitBar,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Dialog.Component.name]: Dialog.Component,
  },
})
export default class OrderConfirm extends Mixins(Mixin) {
  public giftReset = true;
  public chooseGiftCard = false;
  public giftCard = 0;
  public isGiftCard = false;
  public payWallet = false;
  public showIntro = false;
  public submitLoading = false;
  public audienceSelected: Array<ObjectMap> = [];
  public audienceList: Array<member.CommonlyDTO> = [];
  public ticketNum = 0;
  public giftCardAmt!: number; //礼包卡
  public swiperOption = {
    spaceBetween: 30,
    observer: true,
    observeParents: true,
    direction: "horizontal",
    slidesPerView: "auto",
  };
  public audience = {
    show: false,
    select: [],
    showSelected: false,
  };
  public showAddAudience = false;
  public username = "";
  public phonenum = "";
  public form = {
    deliveryWay: "03",
  };
  contentText = "";
  checkedCardList: Array<member.GiftCardRes> = [];
  public dialog: good.ProductRemindDto = {
    remind: false,
    remindTitle: "",
    remindContent: "",
  };

  @Watch("form.deliveryWay", { deep: true })
  changeDeliveryWay(val: string) {
    if (val !== "02") {
      this.postCost = 0.0;
    }
  }
  deliveryTip(type: string): string {
    let tips = "";
    if (type === "01") {
      tips = "支付成功后，前往订单或是票夹查看取票信息。";
    } else if (type === "02") {
      tips = "纸质门票通过快递配送到收货地址。";
    } else if (type === "03") {
      if (this.productSourceEnum === "POLY") {
        tips = "支付成功后，无需取票，直接凭电子码入场";
      } else if (
        this.productSourceEnum === "DM" ||
        this.productSourceEnum === "MY"
      ) {
        tips = "支付成功后，无需取票，直接凭电子码入场。";
      }
    } else if (type === "04") {
      tips = "支付成功后，前往订单或是票夹查看取票信息。";
    } else if (type === "05") {
      tips = "支付成功后，前往订单或是票夹查看取票信息。";
    } else if (type === "06") {
      tips = "支付成功后，无需取票，直接凭身份证入场。。";
    }
    return tips;
  }
  MydeliveryTip(type: string): string {
    let val: any = this.myPickUpTypeList.find(
      (item: any) => item.myPickUpType === type
    );
    // 如果猫眼没有提示就提示原来的。
    if (!val.myTips.length) {
      return this.deliveryTip(type);
    } else {
      return val.myTips;
    }
  }
  public showCoupon = false;
  public productName = "";

  public showTime = "";
  public invoiceNotice = "";
  public address = "";
  public projectImg = "";
  public currentPrice: Array<PricesInformations> = [];

  public uuid = "";
  public userRegType = "";
  public pickUpType = "03";
  public myPickUpTypeList: any = []; //猫眼 配送方式
  public myPickUpType: any = "";
  public integralReward = ""; // 积分奖励说明
  public memberIntegralReward = ""; // 会员积分说明
  public platIntegralReward = ""; //平台积分说明
  public showAddresses = false; //
  public orderdetails = {};
  public categoryId = "";

  public placeName = "";
  public productId = "";
  public productImg = "";
  public realName = "NO_CARD";
  public seat = false;
  public seatInfoList: Array<ObjectMap> = [];
  public showId = 0;

  public theaterId = "";

  public totalPrice = 0.0;
  public discountPrice = 0.0;
  public seatSaleClassInfoList: Array<ObjectMap> = [];
  public unusableCouponList: Array<good.CouponResDto> = [];
  public usableCouponList: Array<good.CouponResDto> = [];
  public freeShipping = false; // 是否支持包邮
  public fullMoney = 0;
  public newDefaultLogistics = true;
  public newAddrList: Array<member.NewAddrRes> = [];
  public oldAddrList: Array<member.OldAddrRes> = [];
  public oldAddrDistributionScope: Array<string> = [];
  public newAddrDistributionScope: Array<string> = [];
  public showPostAreaList = false; // 是否展示新物流模板支持配送区域
  public addrAddress = false;
  public cultureCoupon: Array<good.OrderTicketCouponDetail> = []; //文惠券列表
  public showWHCoupon = false;
  public currentAddr: member.NewAddrRes = {}; //当前选中的收货地址
  public postCost = 0.0; // 邮费
  public thirdCouponId: Array<string> = [];
  public productSourceEnum = "";
  public thirdPart = false; //是否来自第三方
  public canAdd = true; // 是否可以继续添加观演人
  public GiftCardList: Array<member.GiftCardRes> = [];
  public giftCardNum = 0;
  public giftCardAmount = 0.0;
  public showGiftCard = false;
  public orderInfo: order.OrderDetailsParameterDto = {};
  public dom: HTMLElement | null | undefined;
  public dom0: HTMLElement | null | undefined;
  public moreDetailBtn = true;
  public showMoreDetail = true;
  public pollingTime = 0; //轮询次数
  public checkCoupon: ObjectMap = {}; //选中优惠券
  public useParValue = 0;
  @Watch("checkCoupon", { deep: true })
  checkCouponChange(val: ObjectMap): void {
    if (JSON.stringify(val) === "{}") {
      this.checkCouponPrice = 0;
    }
  }

  public actuallyPaidAmt = 0;

  public checkCoupon_show = false; //选中优惠券
  public checkCouponPrice = 0; //选中优惠券的金额
  public checkCouponId = 0; //选中优惠券

  public checkWhCoupon: any = {}; //选中优惠券
  public checkWhCoupon_show = false; //选中优惠券
  public checkWhCouponPrice = 0; //选中优惠券的金额
  public checkWhCouponId = 0; //选中优惠券
  public couponDetailId = -1;
  public postFree = false;

  public param: order.CreateOrder = {
    // fetchTicketWayId: -1, // 演出取票方式ID
    viewerIdList: [],
    couponId: undefined,
    /** 出票方式数组 (从项目详情返回参数中带过来) */
    // ticketModeList: [],
    /** 配送方式 01自助取票 02快递 03:电子票 必填 */
    deliveryWay: "03",

    /** 锁定座位返回的uuid,必填 */
    uuid: "",
    /**
     *
     */
  };
  public giftCardIdList: Array<string> = [];
  public params = {
    uuid: "",
    couponId: undefined,
  };
  get payAmount(): number {
    if (
      Number(this.totalPrice - this.discountPrice - this.giftCardAmount) -
        Number(this.checkCouponPrice) >
      0
    ) {
      return (
        this.totalPrice -
        this.discountPrice -
        this.giftCardAmount -
        this.checkCouponPrice
      );
    } else {
      return 0;
    }
  }

  mounted(): void {
    this.uuid = this.$route.params.uuid;
    this.username = localStorage.getItem("lastUserName")
      ? (localStorage.getItem("lastUserName") as string)
      : "";
    this.phonenum = localStorage.getItem("lastPhoneNum")
      ? (localStorage.getItem("lastPhoneNum") as string)
      : "";
    if (!this.username || !this.phonenum) {
      this.username = "";
      this.phonenum = "";
    }
    this.initData();
  }

  initData(): void {
    this.InterfaceCacheModule.getCacheProductIds(() => {
      // 更新一下字典地址信息
      this.InterfaceCacheModule.getData({
        cacheKey: "region.getRegionList",
        apiMethod: this.$api.dictionaryApi.region.getRegionList,
        callback: ({ data }) => {
          this.DictionaryModule.SET_addresses_PERSIST(data);
        },
      });
    });
    this.$toast.loading({
      duration: 0, // 持续展示 toast
      forbidClick: true,
    });
    // 初始化数据
    this.$api.orderApi.ticketOrder.getAdvance(this.uuid, (da: any) => {
      let data: any = da.data;
      if ((da.code && da.code == "0003") || (da.state && da.state == "FAIL")) {
        //code == "0003"时接口限流时给toast提示、返回返回上一页、
        this.$toast(da.errors[0]);
        setTimeout(() => {
          this.goBack();
        }, 2000);
      } else {
        if (data) {
          this.orderInfo = data;
          this.payWallet = data.payWallet === true ? true : false;
          this.showTime = data.showTime!;
          this.invoiceNotice = data.invoiceNotice!;
          this.categoryId = data.categoryId!;
          this.showId = Number(data.showId!);
          this.pickUpType = !data.pickUpType ? "03" : data.pickUpType;

          this.placeName = data.placeName!;
          this.productId = data.productId!;
          this.productImg = data.productImg!;
          this.productName = data.productName!;
          this.realName = data.realNameEnum!;
          this.seat = data.seat!;
          this.seatInfoList = data.seatInfoList!;
          this.theaterId = data.theaterId!;
          this.productSourceEnum = data.productSourceEnum!;
          this.myPickUpTypeList = data.myPickUpTypeList;
          this.myPickUpType = data.myPickUpTypeList
            ?.map((item: { myPickUpType: any }) => item.myPickUpType)
            .join();
          this.thirdPart = data.productSourceEnum !== "POLY" ? true : false;
          if (this.productId) {
            this.$api.goodApi.product.getProductRemind(
              Number(this.productId),
              ({ data }) => {
                this.dialog = data;
              }
            );
          }

          this.isGiftCard = data.isGiftCard === true ? true : false;
          if (this.isGiftCard) {
            this.getAvailableGiftCard();
          }
          if (data.thirdCouponId) {
            this.thirdCouponId.push(data.thirdCouponId!);
          }

          this.getCouponLit();
          this.getInfo();

          // 实名制展示添加观演人
          if (this.realName !== "NO_CARD") {
            this.getAudienceList();
          }
          this.getAudienceNum();

          this.getDiscountAmt();

          this.$toast.clear();

          // 默认选中第一种取票方式
          if (!/03/.test(this.pickUpType)) {
            this.form.deliveryWay = "01";
            if (!/01/.test(this.pickUpType)) {
              this.form.deliveryWay = "02";
              if (/02/.test(this.pickUpType)) {
                this.getAddressList(true);
              }

              if (!/02/.test(this.pickUpType)) {
                this.form.deliveryWay = "04";

                if (!/04/.test(this.pickUpType)) {
                  this.form.deliveryWay = "05";

                  if (!/05/.test(this.pickUpType)) {
                    this.form.deliveryWay = "06";
                  }
                }
              }
            }
          }
        }
      }
    });
  }
  goDetails(): void {
    this.$router.push(
      `/personal-data/notice?name=积分说明&type=WHAT_ARE_POINTS`
    );
  }
  showMore(): void {
    this.dom0 = document.querySelector(".detail-rich-text") as HTMLElement;
    this.dom = document.querySelector(".detail-rich-text .det") as HTMLElement;
    if (this.dom0) this.dom0.style.height = "";
    // console.log("this.dom0!.style.height mm", this.dom0!.style.height);
    if (this.dom) this.dom.style.height = "auto";
    this.moreDetailBtn = false;
  }
  closeMore(): void {
    this.moreDetailBtn = true;
    this.dom0 = document.querySelector(".detail-rich-text") as HTMLElement;
    this.dom = document.querySelector(".detail-rich-text .det") as HTMLElement;
    if (this.audienceSelected.length > 2) {
      if (this.dom0) this.dom0.style.height = "2.8rem";
    } else {
      if (this.dom0) this.dom0.style.height = "auto";
    }
  }
  addMobileReg(): void {
    let regTel = /^[0-9]*$/;
    if (!regTel.test(this.phonenum)) {
      this.$toast("请输入正确格式的手机号");
    }
    if (this.phonenum.length > 11) {
      this.phonenum = this.phonenum.slice(0, 11);
      return;
    }
  }

  compPostCost(newCurrentAddr: member.NewAddrRes): void {
    let oldCurrentAddr = this.currentAddr;
    if (!oldCurrentAddr || newCurrentAddr.id != oldCurrentAddr.id) {
      monitorEvent("ViewOrder_ChangeAddress", "成功更换收货地址"); // 埋点：确认订单，成功更换收货地址
    }
    this.currentAddr = newCurrentAddr;
    if (this.thirdPart) {
      // 来自第三方
      this.$api.orderApi.ticketOrder.getExpressFee(
        {
          consigneeId: this.currentAddr.id!,
          uuid: this.uuid,
        },
        ({ data }) => {
          this.postCost = data;
          this.postFree = false;
        }
      );
    } else {
      // 计算邮费
      const fullAmount = this.currentAddr.fullAmount || Infinity;
      if (this.freeShipping && this.payAmount >= this.fullMoney) {
        //满足一定金额，邮费为0,且免邮
        this.postCost = 0.0;
        this.postFree = true;
      } else if (
        this.currentAddr.freeShipping ||
        (this.currentAddr.fullAmountFreeShipping &&
          this.payAmount >= fullAmount)
      ) {
        this.postCost = 0.0;
        this.postFree = true;
      } else {
        this.postFree = false;
        this.postCost = Number(this.currentAddr.logisticsPrice!);
      }
    }
    this.getIntegral();
  }
  getAvailableGiftCard(): void {
    this.$api.memberApi.giftCard.selTranslateGiftCard(
      { productId: this.productId, showId: this.showId },
      ({ data }) => {
        if (data) {
          let GiftCardList = data as Array<GiftsCardRes>;
          this.GiftCardList = GiftCardList;
          // .filter((e) => {
          //   return e.status === "1";
          // });
          this.giftCardNum = this.GiftCardList.length;
        }
      }
    );
  }
  goGiftDetails(): void {
    this.showGiftCard = true;

    this.$nextTick(() => {
      let AvailableGiftCard = this.$refs["available-card"] as AvailableGiftCard;
      if (AvailableGiftCard) {
        AvailableGiftCard.initData();
      }
    });
  }
  /*
   * 获取积分说明文案
   */
  getInfo(): void {
    this.InterfaceCacheModule.getData({
      cacheKey: "commonInfo.policy",
      apiMethod: this.$api.cmsApi.commonInfo.policy,
      params: ["ORDER_WHAT_ARE_POINTS"],
      callback: ({ data }) => {
        this.contentText = data;
      },
    });
  }

  /**
   * 选择取票方式
   *
   * @param way 取票方式
   * @param way 取票方式名称
   */
  chooseDeliveryWay(way: string, name: string): void {
    monitorEvent("ViewOrder_ChooseAType", "选择取票方式", name); // 埋点：确认订单，选择取票方式
    if (way == "02") {
      this.showTips();
    } else {
      this.form.deliveryWay = way;
    }
  }

  /*
   * 查看购票协议
   */
  shopTerms(): void {
    let name = "《购票服务条款》";
    let type = "TICKET_PURCHASE";
    this.$router.push(`/personal-data/notice?name=${name}&type=${type}`);
  }

  /*
   * 打开选择地址组件时
   */
  openAddresses(): void {
    this.$nextTick(() => {
      (this.$refs.address as MyAddressList).refreshData();
    });
  }

  /*
   * 打开地址详情的弹框时执行
   */
  openAddressDetail(): void {
    this.$nextTick(() => {
      (this.$refs["address-detail"] as AddressDetail).refreshData();
    });
  }

  //文惠券列表
  getCouponLit(): void {
    let pro_arr: any = [];
    this.thirdCouponId.forEach((id, index) => {
      pro_arr.push(this.couponLit(id));
    });
  }
  couponLit(id: string) {
    return new Promise(() => {
      this.$api.goodApi.coupon.cultureCoupons(id, (data) => {
        // this.cultureCoupon.push(data) ;
        // console.log("文惠券11111", data);
      });
    });
  }
  // 选择观演人数量 总票价
  getAudienceNum(): void {
    this.ticketNum = 0;
    this.totalPrice = 0.0;
    if (this.realName === "ORDER_ONE_CARD") {
      this.ticketNum = 1;
    } else if (this.realName === "TICKET_ONE_CARD") {
      if (this.seat) {
        this.seatInfoList.filter((item: ObjectMap) => {
          this.ticketNum += item.num;
          // this.totalPrice += parseFloat(item.price) * item.num;
        });
      } else {
        this.seatInfoList.filter((item: ObjectMap) => {
          this.ticketNum += item.num;
          // this.totalPrice += parseFloat(item.price) * item.num;
        });
      }
    }
  }
  // 新增观演人
  addAudience(): void {
    monitorEvent("ViewOrder_ClickAdd", "点击新增观演人"); // 埋点：确认订单，点击新增观演人
    this.showAddAudience = true;
    this.$nextTick(() => {
      let addAudience = this.$refs["add-audience"] as AddAudience;
      if (addAudience) {
        addAudience.openAdd(this.canAdd);
      }
    });
  }
  // 刷新观演人列表
  refreshAduience(): void {
    this.getAudienceList();
    this.audience.show = false;
  }
  // 获取观演人列表
  getAudienceList(): void {
    this.$api.memberApi.viewer.getViewers((data) => {
      this.audienceList = data.data;
      this.canAdd = data.data.length < 20 ? true : false;
      this.audienceSelected = [];
      this.$nextTick(() => {
        if (this.ticketNum >= 1 && this.audienceList.length === 1) {
          this.selectAudience(this.audienceList[0]);
        }
      });
    });
  }
  // 选择观演人
  selectAudience(item: member.CommonlyDTO): void {
    const index = this.audienceSelected.findIndex((it) => item === it);
    if (index >= 0) {
      this.audienceSelected.splice(index, 1);
    } else if (this.audienceSelected.length < this.ticketNum) {
      this.audienceSelected.push(item);
    }

    this.$nextTick(() => {
      this.dom0 = document.querySelector(".detail-rich-text") as HTMLElement;
      this.dom = document.querySelector(
        ".detail-rich-text .det"
      ) as HTMLElement;
      if (this.moreDetailBtn === false) {
        if (this.audienceSelected.length === 2) {
          this.moreDetailBtn = true;
          this.showMoreDetail = false;
          if (this.dom) this.dom.style.padding = "0";
        } else if (this.audienceSelected.length < 2) {
          if (this.audienceSelected.length === 1) {
            if (this.dom0) this.dom0.style.height = "1rem";
          } else {
            if (this.dom0) this.dom0.style.height = "auto";
          }
        } else {
          //3个及 3个以上
          if (this.dom0) this.dom0.style.height = "auto";
          this.showMoreDetail = true;
          if (this.dom) this.dom.style.paddingBottom = "0.35rem";
        }
      } else {
        if (this.audienceSelected.length < 3) {
          this.showMoreDetail = false;
          if (this.audienceSelected.length === 1) {
            if (this.dom0) this.dom0.style.height = "1rem";
          } else {
            if (this.dom0) this.dom0.style.height = "auto";
          }
        } else {
          if (this.dom) this.dom.style.paddingBottom = "0.35rem";
          if (this.dom0) this.dom0.style.height = "2.8rem";
          this.showMoreDetail = true;
        }
      }
    });
  }
  // 获取收货地址列表
  getAddressList(first?: boolean): void {
    this.$api.memberApi.address.getAddressWithPrice(
      {
        showId: Number(this.showId),
        commodityInfoId: Number(this.productId),
        /** 类型,1代表票务快递信息，2代表商品快递信息 */
        type: "1",
        uuid: this.$route.params.uuid,
      },
      (data) => {
        this.freeShipping = data.data.freeShipping!;
        this.fullMoney = data.data.fullMoney!;
        this.newDefaultLogistics = data.data.newDefaultLogistics!;
        this.newAddrList = data.data.newAddrList!;
        this.oldAddrList = data.data.oldAddrList ? data.data.oldAddrList : [];
        this.oldAddrDistributionScope = data.data.oldAddrDistributionScope!;
        this.newAddrDistributionScope = data.data.newAddrDistributionScope!;
        if (first && this.newAddrList.length) {
          if (this.newDefaultLogistics && this.newAddrList) {
            this.setAddress(this.newAddrList[0]);
          }
          if (!this.newDefaultLogistics && this.oldAddrList) {
            this.setAddress(this.oldAddrList[0]);
          }
        }
      }
    );
  }
  // 编辑/新增收货地址
  editAddress(first: boolean): void {
    if (first) {
      this.addrAddress = true;
    } else {
      this.showAddresses = true;
    }
  }
  // 刷新收货地址
  refreshAddress(): void {
    this.getAddressList(true);
  }
  setAddress(data: member.NewAddrRes): void {
    this.showAddresses = false;
    if (data) {
      this.compPostCost(data);
    } else {
      this.setAddress(this.newAddrList[0]);
    }
  }
  // 获取积分说明
  getIntegral(): void {
    this.$api.memberApi.integral.getIntegral(
      {
        actuallyPrice: this.payAmount,
        showId: Number(this.showId),
      },
      ({ data }) => {
        this.integralReward = data.integralReward!;
        this.memberIntegralReward = data.memberIntegralReward!;
        this.platIntegralReward = data.platIntegralReward!;
      }
    );
  }
  // 获取本订单可优惠金额
  getDiscountAmt(): void {
    this.totalPrice = this.seatInfoList.reduce((pre, next) => {
      return (
        pre +
        (next.num *
          100 *
          ((typeof next.price === "number"
            ? next.price
            : parseFloat(next.price)) *
            100)) /
          (100 * 100)
      );
    }, 0); //680

    this.$api.orderApi.ticketOrder.calculateDiscountAmt(
      { uuid: this.uuid },
      (da: any) => {
        var data = da.data;
        console.log(da);
        if (
          (da.code && da.code == "0003") ||
          (da.state && da.state == "FAIL")
        ) {
          //code == "0003"时接口限流时给toast提示、返回返回上一页、
          this.$toast(da.errors[0]);
          setTimeout(() => {
            this.goBack();
          }, 2000);
        } else {
          if (this.totalPrice > (data.discountAmt || 0)) {
            this.discountPrice = this.totalPrice - (data.discountAmt || 0);
          } else {
            this.discountPrice = this.totalPrice - (data.discountAmt || 0);
          }
          this.seatSaleClassInfoList = data.seatSaleClassInfoList!;
          if (
            this.seatSaleClassInfoList &&
            this.seatSaleClassInfoList.length !== 0
          ) {
            this.actuallyPaidAmt = this.seatSaleClassInfoList.reduce(
              (pre, next) => {
                if (next.gittmark !== "1") {
                  return pre + next.actuallyPaidAmt;
                } else {
                  return pre + 0;
                }
              },
              0
            );
          }
          this.getCounpon();
          this.getIntegral();
        }
      },
      (errorMessage) => {
        if (
          typeof errorMessage === "string" &&
          errorMessage.indexOf("@_@") !== -1
        ) {
          Dialog.alert({
            title: "创建订单失败",
            message: errorMessage.split("@_@")[1],
          }).then(() => {
            this.$router.go(-1);
          });
        } else {
          this.$toast(errorMessage);
        }
      }
    );
  }
  // 选中礼品卡
  checkGiftCard(checkedList: any[]): void {
    this.checkedCardList = checkedList;
    this.giftCardIdList = [];
    if (checkedList.length !== 0) {
      checkedList.forEach((item) => {
        this.giftCardIdList.push(item.id);
      });
    } else {
      this.giftCardIdList = [];
    }
    this.showGiftCard = false;
    let allAmount = 0;
    checkedList.forEach((item) => {
      allAmount = allAmount + item.balance!;
    });
    // 判断选中礼品卡的金额 和 （商品金额 ， 折扣金额  选中优惠券金额 ）的大小关系
    let diffAmount =
      Number(this.totalPrice - this.discountPrice) -
      Number(this.checkCouponPrice);
    if (diffAmount <= 0) {
      // 差值是 0 元 ，用户选择了礼品卡
      this.giftCardAmount = 0;
    } else {
      if (Number(allAmount) >= diffAmount) {
        this.giftCardAmount = Number(diffAmount.toFixed(2));
      } else {
        this.giftCardAmount = Number(allAmount.toFixed(2));
      }
    }
    this.chooseGiftCard = checkedList.length !== 0;
    if (this.form.deliveryWay === "02") {
      this.compPostCost(this.currentAddr);
    } else {
      this.$nextTick(() => {
        this.getIntegral();
      });
    }
  }

  // 获取优惠券列表
  goCouponList(): void {
    this.showCoupon = true;
  }
  // 重置礼品卡
  resetGiftCard(): void {
    this.chooseGiftCard = false;
    this.giftReset = false;
    this.checkedCardList = [];
    this.giftCardAmount = 0;
    this.getAvailableGiftCard();
    this.$nextTick(() => {
      this.giftReset = true;
    });
  }
  // 选中优惠券
  checkItem(item: Record<string, unknown>): void {
    if (JSON.stringify(item) !== "{}") {
      if (
        item.couponDetailId !== this.checkCoupon.couponDetailId ||
        JSON.stringify(this.checkCoupon) === "{}"
      ) {
        this.resetGiftCard();
      }
      this.checkCoupon = item;
      // this.checkCouponPrice = this.checkCoupon.parValue.toFixed(2);
      this.checkCouponId = this.checkCoupon.couponId;
      this.checkCoupon_show = true;
      this.couponDetailId = this.checkCoupon.couponDetailId;
      this.getUsePreVal();
    } else {
      if (JSON.stringify(this.checkCoupon) !== "{}") {
        this.resetGiftCard();
      }
      this.checkCoupon = item;
      this.checkCouponPrice = 0.0;
      this.useParValue = 0.0;
      this.checkCoupon_show = false;
    }
    if (this.form.deliveryWay === "02") {
      this.compPostCost(this.currentAddr);
    } else {
      this.$nextTick(() => {
        this.getIntegral();
      });
    }
  }
  // 计算优惠券实际显示金额
  getUsePreVal(): void {
    // if (
    //   Number(this.checkCouponPrice) >=
    //   Number(this.totalPrice - this.discountPrice)
    // ) {
    //   this.useParValue = Number(this.totalPrice - this.discountPrice);
    // } else {
    //   this.useParValue = Number(this.checkCouponPrice);
    // }
    const params = {
      uuid: this.uuid,
      couponId: this.couponDetailId,
      deliveryWay: this.form.deliveryWay,
    };
    this.$api.orderApi.ticketOrder.couponDeduction(params, ({ data }: any) => {
      this.useParValue = data;
      this.checkCouponPrice = data;
    });
  }
  //选中wen惠券
  checkWhItem(val: Record<string, unknown>): void {
    this.resetGiftCard();
    this.checkWhCoupon = val;
    this.checkWhCouponPrice = this.checkWhCoupon.parvalue.toFixed(2);
    this.checkWhCouponId = this.checkWhCoupon.couponId;
    this.checkWhCoupon_show = true;
  }
  getCounpon(): void {
    this.$api.goodApi.coupon.coupons(
      {
        categoryId: this.categoryId,
        orderTotalAmount: this.actuallyPaidAmt,
        productId: Number(this.productId),
        showId: Number(this.showId),
      },
      (data) => {
        this.unusableCouponList = data.data.unusableCouponList!;
        this.usableCouponList = data.data.usableCouponList!;
      }
    );
  }

  // 获取文惠券列表
  getWhCoupon(): void {
    this.showWHCoupon = true;
    this.giftCard;
    // this.$api.goodApi.coupon.cultureCoupons(this.thirdCouponId, (data) => {
    //   this.cultureCoupon = data.data;
    //   console.log("文惠券", data.data, 788);

    // });
  }
  // 快递取票提示框
  showTips(): void {
    if (this.form.deliveryWay !== "02") {
      Dialog.confirm({
        message: "您确定使用快递取票下单吗？",
      })
        .then(() => {
          this.getAddressList(true);
          this.form.deliveryWay = "02";
        })
        .catch(() => {
          // on cancel
        });
    }
  }
  // 提交订单  jhy add
  onSubmit(): void {
    monitorEvent("ViewOrder_ClickSubmitOrder", "点击提交订单"); // 埋点：确认订单，点击提交订单
    const audienceSelected = this.audienceSelected;
    if (this.realName !== "NO_CARD") {
      if (audienceSelected.length === 0) {
        this.$toast("请选择观演人");
        return;
      }
      if (audienceSelected.length < this.ticketNum) {
        let rest = this.ticketNum - audienceSelected.length;
        this.$toast("请再选择" + rest + "个观演人");
        return;
      }
    }
    const username = this.username;
    const phonenum = this.phonenum;
    const currentAddr = this.currentAddr;
    if (
      this.form.deliveryWay === "01" ||
      this.form.deliveryWay === "03" ||
      this.form.deliveryWay === "04" ||
      this.form.deliveryWay === "05" ||
      this.form.deliveryWay === "06"
    ) {
      if (!username) {
        this.$toast("请填写联系人");
        return;
      }
      if (!phonenum) {
        this.$toast("请填写手机号");
        return;
      }
      if (
        !/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(
          phonenum
        )
      ) {
        this.$toast("请输入正确的手机号");
        return;
      }
    } else if (
      this.form.deliveryWay === "02" &&
      (!currentAddr || !currentAddr.id)
    ) {
      this.$toast("请添加收货地址");
      return;
    }

    let viewerIds: Array<string> = [];
    audienceSelected.forEach((e) => {
      viewerIds.push(e.id);
    });
    this.couponDetailId =
      JSON.stringify(this.checkCoupon) !== "{}"
        ? this.checkCoupon.couponDetailId
        : -1;
    const newParam: order.CreateOrder = {
      deliveryWay: this.form.deliveryWay,
      // cultureCouponId: '',
      uuid: this.uuid,
      viewerIdList: viewerIds,
    };
    if (
      this.form.deliveryWay == "01" ||
      this.form.deliveryWay == "03" ||
      this.form.deliveryWay === "04" ||
      this.form.deliveryWay === "05" ||
      this.form.deliveryWay === "06"
    ) {
      newParam.consignee = username;
      localStorage.setItem("lastUserName", username);
      newParam.consigneePhone = phonenum;
      localStorage.setItem("lastPhoneNum", phonenum);
    } else if (this.form.deliveryWay == "02") {
      newParam.consignee = currentAddr.addrName;
      newParam.consigneePhone = currentAddr.addrMobile;
      newParam.consigneeId = currentAddr.id;
    }
    if (this.newDefaultLogistics === false) {
      newParam.deliveryAreaId = currentAddr.addrDispCityId;
    }
    if (this.couponDetailId !== -1) {
      newParam.couponId = this.couponDetailId;
    }
    if (this.giftCardIdList.length !== 0) {
      // 使用礼品卡
      newParam.giftCardIdList = this.giftCardIdList;
    }
    this.param = newParam;

    this.submitOrder();
    // this.param.productSourceEnum=this.productSourceEnum;
    // if (this.orderInfo.cityCode === this.LocationModule.cityCode) {
    //   this.submitOrder();
    // } else {
    //   Dialog.confirm({
    //     message: "当前购买的演出城市与所在定位城市不一致。",
    //   })
    //     .then(() => {
    //       this.submitOrder();
    //     })
    //     .catch(() => {
    //       console.log(33);
    //     });
    // }
  }

  /*
   * 提交
   */
  submitOrder(): void {
    this.$api.orderApi.ticketOrder.quickOrder(this.param, ({ data }) => {
      setTimeout(() => {
        this.submitLoading = true;
        this.pollingResult(data);
        this.pollingTime = 1;
      }, 500);
    });
  }
  pollingResult(key: string): void {
    this.$api.orderApi.ticketOrder.quickOrderResult(
      key,
      ({ data }) => {
        if (data) {
          this.submitLoading = false;
          if (this.payAmount + this.postCost === 0) {
            this.$router.replace({
              path: `/paysuccess/ticket`,
              query: {
                status: "TRADE_SUCCESS",
                actuallyPaidAmt: `${data.actuallyPaidAmt!}`,
                orderExpireTime: `${data.orderExpireTime!}`,
                orderId: `${data.orderId!}`,
                orderType: "SHOW",
                payWallet: `${this.payWallet}`,
                productSourceEnum: this.productSourceEnum,
                productId: this.productId,
              },
            });
          } else {
            this.$router.replace({
              path: "/payconfirm",
              query: {
                orderId: `${data.orderId!}`,
                orderType: "SHOW",
                productId: this.productId,
              },
            });
          }
        } else {
          if (this.pollingTime >= 5) {
            // 抛出异常
            this.submitLoading = false;
            this.$toast("订单提交失败，请稍后重试");
          } else {
            // 还结果，继续轮询
            setTimeout(() => {
              this.pollingResult(key);
              this.pollingTime += 1;
            }, 2000);
          }
        }
      },
      (error) => {
        this.submitLoading = false;
        error && this.$toast(error);
      }
    );
  }

  goBack(): void {
    if (this.productSourceEnum === "DM") {
      this.$router.go(-3);
    } else {
      this.$router.go(-1);
    }

    //跟需求确认之后 让改的。。。
    // Dialog.confirm({
    //   title: "确定重新选座吗？",
    //   message: `<div>
    //   <span>
    //   返回将解除座位锁定，您的坐位可能被别人抢占，确定返回上一步吗？
    //   </span>
    //     </div>
    //   `,
    // }).then(() => {
    //   this.$router.go(-1);
    // });
  }
}
